var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"products"},[_c('v-breadcrumbs',{staticClass:"hidden-sm-and-down",attrs:{"items":_vm.breadcrumbs}}),_c('CategoryTitle',{attrs:{"category":_vm.category,"selectedCategory":_vm.selectedCategory,"count":_vm.count,"height":"150px"}}),(!_vm.categoryId)?_c('v-container',[_c('v-row',_vm._l((_vm.category.children),function(subCategory){return _c('v-col',{key:subCategory.categoryId,attrs:{"cols":"6","sm":"4","md":"3","lg":"2"}},[_c('v-card',{attrs:{"outlined":"","height":"100%","to":{
            name: 'Category',
            params: {
              pathMatch: _vm.category.slug
            },
            query: { categoryId: subCategory.categoryId }
          }}},[_c('v-img',{staticClass:"center-img",attrs:{"contain":"","src":subCategory.metaData.category_info.ICON,"alt":subCategory.name,"height":"150"}}),_c('v-card-title',{staticClass:"category-block-title"},[_vm._v(_vm._s(subCategory.name))])],1)],1)}),1)],1):_c('ProductListGrid',{key:_vm.category.categoryId,attrs:{"parentCategoryId":_vm.category.categoryId,"categoryId":_vm.categoryId,"hidePromoFilter":true,"hideFilterString":"Categorie","position":"category_leaflet"},on:{"productsCount":_vm.updateCount}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }