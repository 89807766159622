<template>
  <div
    class="category"
    :class="{ 'grey lighten-2': templateName != 'categoryMain' }"
  >
    <CategoryTitle :category="category" :count="count" />
    <Breadcrumb v-if="$vuetify.breakpoint.smAndUp" :items="breadcrumb" />
    <v-container v-if="headerBanner.length > 0">
      <ProposalSlider :proposals="headerBanner" />
    </v-container>
    <component
      :is="templateName"
      :key="key"
      @productsCount="updateCount"
    ></component>
    <v-container v-if="footerBanner.length > 0">
      <ProposalSlider :proposals="footerBanner" />
    </v-container>
  </div>
</template>

<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import ProposalSlider from "@/components/proposal/ProposalSlider.vue";
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";

import categoryMain from "@/components/category/CategoryMain";
import categoryMed from "@/components/category/CategoryMed";
import categoryLeaflet from "@/components/category/CategoryLeaflet";
import categoryCustom from "@/components/category/CategoryCustom";

import categoryMixins from "~/mixins/category";
import deliveryReactive from "~/mixins/deliveryReactive";

import { mapProposal } from "~/service/ebsn.js";
import camelCase from "lodash/camelCase";
import split from "lodash/split";

export default {
  name: "Category",
  mixins: [categoryMixins, deliveryReactive],
  components: {
    categoryMain,
    categoryMed,
    categoryLeaflet,
    categoryCustom,
    ProposalSlider,
    CategoryTitle,
    Breadcrumb
  },
  data() {
    return {
      key: 1,
      count: -1
    };
  },
  computed: {
    templateName() {
      if (this.category.navigationTypeId == 1) {
        return "categoryLeaflet";
      } else if (this.category.template) {
        let templateName = camelCase(this.category.template);
        return templateName;
      } else {
        return "categoryMed";
      }
    },
    ...mapProposal({
      headerBanner: "header-banner",
      footerBanner: "footer-banner"
    })
  },
  methods: {
    updateCount(count) {
      this.count = count;
    },
    async reload() {
      let slug = split(this.category.slug, "@")[0];
      await this.resetFilters(slug);
      await this.setCategory({ slug: slug, force: true });
      this.key += 1;
    }
  }
  // ,
  // async created() {
  //   await this.resetFilters(this.category.slug);
  // },
  // watch: {
  //   async $route(to) {
  //     let slug = to.params.pathMatch;
  //     await this.resetFilters(slug);
  //     await this.setCategory({ slug });
  //   }
  // }
};
</script>
