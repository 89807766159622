<template>
  <div>
    <ProductListGrid
      v-bind:parentCategoryId="category.categoryId"
      :key="category.categoryId"
      :category="category"
      @productsCount="updateCount"
      position="category_med"
    />
    <div v-if="count == 0" class="secondary pb-5">
      <ProductListSlider
        title="Scopri le nostre promozioni"
        paginationClass="swiper-pagination-promo"
        class="secondary"
        :key="key"
        :layout="21"
        :category-id="category.categoryId"
        :shuffle="true"
        position="category_med_no_results"
      />
    </div>
    <v-container>
      <FooterCardsGrid :cardsName="['serviziSpesa', 'storeLocator']" />
    </v-container>
    <FaqBar />
  </div>
</template>
<script>
import ProductListGrid from "@/components/product/ProductListGrid.vue";
import FaqBar from "@/components/registration/FaqBar.vue";
import FooterCardsGrid from "@/components/cards/FooterCardsGrid.vue";
import ProductListSlider from "@/components/product/ProductListSlider.vue";

import categoryMixins from "~/mixins/category";
import get from "lodash/get";

export default {
  name: "CategoryMed",
  mixins: [categoryMixins],
  components: {
    ProductListGrid,
    FaqBar,
    FooterCardsGrid,
    ProductListSlider
  },
  data() {
    return { count: -1, key: 0 };
  },
  methods: {
    updateCount(count) {
      this.count = count;
      this.$emit("productsCount", count);
    }
  },
  metaInfo() {
    let text = this.category.name;
    if (this.breadcrumb.length > 0) {
      text = this.breadcrumb[this.breadcrumb.length - 2].text;
    }
    return {
      title: get(
        this.category,
        "metaData.category_seo.SEO_TITLE",
        this.category ? this.category.name : ""
      ),
      meta: [
        {
          vmid: "description",
          name: "description",
          content: get(
            this.category,
            "metaData.category_seo.SEO_DESCRIPTION",
            this.$t("meta.category.secondAndThirdLevel.description", [
              this.category.name,
              text
            ])
          )
        }
      ]
    };
  }
};
</script>
